import { MainController } from './main_controller';
export default class ShopscanController extends MainController {
  connect() {
    this.element['controller'] = this
    //$('#shop-show__scan-mode').css('height', $(window).height() - 95)
    $('body').css('backgroundColor', '#FFFFFF')
    let content_height = $(window).height() - 95 - $('#shop-header').height() - $('#shop-show__scan-mode').height()
    $('#shop-show__scan-mode__cart-content').addClass('active')
    $('#shop-show__scan-mode__cart-content').css('height', content_height)
    setTimeout(() => {
      $('#shop-show__scan-mode__footer').css('visibility', 'visible') 
    }, 300)
    
    this.refresh()
    //this.openSearchProduct()
  }

  refresh() {
    $.get('/cart/load_scan')
  }

  refreshContact() {
    $.get('/shops/refresh_contact')
  }

  removeContact() {
    $.get('/cart/remove_contact')
  }

  openSearchProduct() {
    $('.loading_app_container').removeClass('d-none')
    $.get('/shops/modal_search_product')
  }

  remove(e) {
    $('.loading_app_container').removeClass('d-none')
    let data = $(e.currentTarget).parents('tr').data()
    let element = $(e.currentTarget).parents('tr')
    $.ajax({
      url: `/cart/${data.cartId}?type=${data.type}`,
      type: 'DELETE',
      success: (data) => {
        if(data.code == 200) {
          element.remove()
          this.refresh()
          $('.loading_app_container').addClass('d-none')
        }
      }
    })
  }  

  openDiscount() {
    $.get('/cart/scan_open_discount')
  }

  checkout() {
    let paymentMode = $(this.element).data().paymentMode
    if(paymentMode == 'both') {
      Turbolinks.visit('checkout/choose_payment_mean')
    } else if(paymentMode == 'pos') {
      Turbolinks.visit('checkout/payment_pos')
    } else {
      Turbolinks.visit('checkout/payment_borne')
    }
  }

  remove_discount() {
    $.get('/cart/remove_discount', (data) => {
      this.refresh()
    })
  } 

  fakeInitializePayment() {
    try {
      app.initializePayment(JSON.stringify({basketPrice: 5, id: "75817e23-c5f5-4d61-8b6e-f28ebeba3fee"}));
    } catch (e) {
      console.log(e)
    }    
  } 

  fakeTransactionAllow() {
    let order = {
      "bag": {
        "id": "75817e23-c5f5-4d61-8b6e-f28ebeba3fee",
        "shopId": "08dc9f2d-34a7-4f51-8a9b-b429ea0ae344",
        "sourceOrderType": 4,
        "type": 0,
        "status": 0,
        "basketPrice": 5,
        "basketOriginPrice": 5,
        "globalUserId": null,
        "continousSequence": -1,
        "distributionType": 0,
        "totalPromotionAmount": 0,
        "timeSelected": true,
        "basketItemsOrder": [
          "9c5988b1-6d66-4998-b2bd-b5c9d0d1e182"
        ],
        "usedPromotions": null,
        "promotionResults": null,
        "deliveryStreet": null,
        "deliveryZipCode": null,
        "deliveryCity": null,
        "totalDiscountAmount": "0.0",
        "totalDiscountLinesAmount": "0.0",
        "create": 1721389509,
        "update": 1721389509,
        "date": 1721389509,
        "basketProducts": [
          {
            "id": "9c5988b1-6d66-4998-b2bd-b5c9d0d1e182",
            "shopId": "08dc9f2d-34a7-4f51-8a9b-b429ea0ae344",
            "orderId": "75817e23-c5f5-4d61-8b6e-f28ebeba3fee",
            "productCategoryId": null,
            "productCategoryParentId": "00000000-0000-0000-0000-000000000000",
            "productId": "c19a0c86-ae12-4b96-a4e2-0056d027da2b",
            "productUniqueId": "25f1c510-39ab-460b-a34a-1ef5b2539e56",
            "productSubInformation": "Fabrication: Bleu",
            "productName": "Crêpe complète Bleu",
            "customProductName": null,
            "productPriceUnitId": "dca7980e-e985-45b1-8927-c42dfd31e077",
            "ecoTaxe": 0,
            "unitId": "d4196180-cc0e-4c8b-8b66-46309ab0ba85",
            "unitLabel": "Article(s)",
            "quantity": 1,
            "vatRate": 0,
            "originalPrice": 5,
            "totalPriceTTC": 5,
            "vatId": "1030c7c9-b6f7-4878-b9dc-2551d0f5eb5b",
            "productVariationId": "b1a2bb85-5efd-4f30-913a-cee7e47de4ef",
            "gift": false,
            "unitPriceTTC": 5,
            "productPriceUnitPrice": 0,
            "mixableAttributesPrice": 0,
            "unitSymbol": "art",
            "vatCode": "SP1",
            "promotionDiscountAmount": 0,
            "pickupNow": false,
            "discountAmount": "0.0",
            "discountOrderByLine": "0.0",
            "discount": null,
            "sendToProduction": 0,
            "compositionAttributes": [],
            "fabricationAttributes": [
              "bcf191d4-f16a-4270-bdfd-e0812d68b2c8"
            ],
            "mixableAttributes": [],
            "seasonings": [],
            "removableIngredients": [],
            "sideDishesSelection": [],
            "supplementSelection": [],
            "create": 1721389509,
            "update": 1721389509
          }
        ],
        "basketMenus": [],
        "companies": [],
        "customers": [],
        "withdrawalCode": 5796,
        "deliveryComment": null,
        "payments": [],
        "discount": null,
        "pickUpLocation": "00000000-0000-0000-0000-000000000000",
        "externalId": "6",
        "dailyOrderNumber": 2,
        "deviceId": "00000000-0000-0000-0000-000000000000",
        "employeeId": "00000000-0000-0000-0000-000000000000",
        "dayId": "00000000-0000-0000-0000-000000000000",
        "quartId": "00000000-0000-0000-0000-000000000000",
        "elementId": "00000000-0000-0000-0000-000000000000"
      },
      "address": {
        "id": null,
        "globalUserId": null,
        "address": null,
        "zipCode": null,
        "city": null,
        "country": "France"
      },
      "externalTicket": null,
      "externalTicketSalesLines": [],
      "externalTicketCashDataSummary": [],
      "externalTicketPaymentData": null
    }

    console.log(order)

    try {
      app.orderSendToProduction(JSON.stringify({order: order}));
    } catch(e) {
      console.log(e)
    }
  }
}